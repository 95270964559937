import APIService from './APIService.js';
import { useState } from 'react';
import swal from 'sweetalert';

const Form = (props) => {
    const [userEmail, setUserEmail] = useState('');

    const insertRecord = () => {
        APIService.WriteEmailToBackend({ userEmail })
            .then((response) => props.postedEmail(response))
            .catch(error => console.log('error!', error))
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        insertRecord()
        swal(`Thank you for subscribing. We'll contact you at ${userEmail} soon`)
        setUserEmail('')
    }

    return (
        <form className="App-header" onSubmit={handleSubmit}>
            <h1>Vote Peace for Gaza 🕊️</h1>
            <p>Submit your email address to stay up to date with this project</p>
            <input
                type="text"
                id="contact_email"
                name="contact_email"
                placeholder="voter@us.org"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}></input>
            <button type="submit">Submit Email Address</button>
        </form>
    )
}

export default Form;