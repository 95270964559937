export default class APIService {
    static WriteEmailToBackend(body) {
        return fetch(`write_user_email`, {
            'method': 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then(response => response.json())
            .catch(error => console.log(error))
    }
}