import '../App.css';
import Form from './Form';
import { useState } from 'react';

function App() {
  return (
    <div className="App">
      <Form />
    </div>
  );
}

export default App;
